<template>
  <div class="row mt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h2 class="text-center">Detail Payment Appstore</h2>
          <div class="row" v-if="detailOrder">
            <div class="col-md-12">
              <div class="card mt-2">
                <div class="card-header bg-primary">
                  <h5>Order Transaction Detail</h5>
                </div>
                <div class="card-body">
                  <b-overlay :show="isLoadingDetailOrder" rounded="sm">
                    <div class="form-group row">
                      <label class="col-sm-2">Order ID</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <span
                          @click="routerOrderDetail(detailOrder?.id)"
                          style="cursor: pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="click here to go to detail order page"
                        >
                          {{ detailOrder?.id }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Order Number</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ detailOrder?.order_number }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Email</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div v-if="detailOrder?.users">
                          <router-link
                            :to="{
                              path:
                                '/customers/detail/' +
                                detailOrder?.users?.email,
                            }"
                            target="_blank"
                          >
                            {{ detailOrder?.users?.email }}
                          </router-link>
                        </div>
                        <div v-else>-</div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Transaction Time</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ formatDate(detailOrder?.created) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Modified</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ formatDate(detailOrder?.modified) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Status Order</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div class="float-left" style="margin-top: 0px">
                          <paymentview :payment="+detailOrder?.order_status" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Renewal Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <span
                          v-if="detailOrder?.is_renewal"
                          class="badge badge-pill badge-primary"
                        >
                          Renewal
                        </span>
                        <span v-else class="badge badge-pill badge-danger">
                          Non-Renewal
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Total Amount</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ detailOrder?.currency_code?.toUpperCase() }}
                        {{
                          formatPrice(
                            detailOrder?.total_amount,
                            detailOrder?.currency_code,
                          )
                        }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Discount</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ detailOrder?.currency_code?.toUpperCase() }}
                        {{
                          getDiscountPrice(
                            detailOrder?.total_amount,
                            detailOrder?.final_amount,
                            detailOrder?.currency_code,
                          )
                        }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Final Amount</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ detailOrder?.currency_code?.toUpperCase() }}
                        {{
                          formatPrice(
                            detailOrder?.final_amount,
                            detailOrder?.currency_code,
                          )
                        }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Payment Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ detailOrder?.payment?.name }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Platform Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div class="float-left">
                          <Platform :platform="+detailOrder?.platform_id" />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Client ID</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ clientID(detailOrder?.client_id) }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Tier Code</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{
                          detailOrder?.tier_code ? detailOrder?.tier_code : '-'
                        }}
                      </div>
                    </div>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="detailOrder">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header bg-primary">
                  <h5>Orderlines Detail</h5>
                </div>
                <div class="card-body">
                  <!-- <div v-else> -->
                  <b-overlay :show="isLoadingDetailOrder" rounded="sm">
                    <b-table
                      id="table-transition"
                      show-empty
                      :fields="headerOrderline"
                      hover
                      responsive="xl"
                      :items="detailOrder?.orderlines"
                      sort-icon-left
                    >
                      <template #cell(No)="row">
                        {{ row.index + 1 }}.
                      </template>

                      <template #cell(id)="row">
                        {{ row.item.id }}
                      </template>

                      <template #cell(item_name)="row">
                        {{ row.item.name }}
                      </template>

                      <template #cell(qty)="row">
                        {{ row.item.quantity }}
                      </template>

                      <template #cell(price)="row">
                        {{ row.item.currency_code }}
                        {{
                          formatPrice(row.item.price, row.item.currency_code)
                        }}
                      </template>

                      <template #cell(discount)="row">
                        {{ row.item.currency_code }}
                        {{
                          getDiscountPricePerItem(
                            row.item.price,
                            row.item.final_price,
                            row.item.currency_code,
                            row.item.quantity,
                          )
                        }}
                      </template>

                      <template #cell(final_price)="row">
                        {{ row.item.currency_code }}
                        {{
                          formatPrice(
                            row.item.final_price,
                            row.item.currency_code,
                          )
                        }}
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="detailOrder">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header bg-primary">
                  <h5>Detail Payment</h5>
                </div>
                <div class="card-body">
                  <b-overlay :show="isLoadingDetailPayment" rounded="sm">
                    <div
                      v-if="
                        this.detailOrder?.paymentgateway_id == applePayment &&
                          detailPayment?.paymentappleiaps?.length
                      "
                    >
                      <Itunes :orderdetail="detailPayment" />
                    </div>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card mt-2">
                <div class="card-header bg-primary">
                  <h5>Appstore Transactions</h5>
                </div>
                <div class="card-body">
                  <b-overlay :show="isLoading" rounded="sm">
                    <b-table
                      sticky-header="350px"
                      :items="items"
                      :fields="fields"
                      :busy="isLoading"
                      :isLoading="isLoading"
                      show-empty
                      responsive="xl"
                      hover
                      sort-icon-left
                    >
                      <template #cell(No)="row">
                        {{ row.index + 1 }}.
                      </template>

                      <template #cell(product_id)="row">
                        <div style="width: 350px">
                          {{ row.item.product_id ? row.item.product_id : '-' }}
                        </div>
                      </template>

                      <template #cell(environment)="row">
                        <div
                          style="width: 150px"
                          v-if="row.item.environment == 'Production'"
                        >
                          <span class="badge badge-pill bg-primary">{{
                            row.item.environment ? row.item.environment : '-'
                          }}</span>
                        </div>
                        <div
                          style="width: 150px"
                          v-else-if="row.item.environment == 'Sandbox'"
                        >
                          <span class="badge badge-pill bg-warning">{{
                            row.item.environment ? row.item.environment : '-'
                          }}</span>
                        </div>
                        <div v-else>-</div>
                      </template>

                      <template #cell(notification_type)="row">
                        <div style="width: 180px">
                          <div v-if="!row.item.notification_type">-</div>
                          <div v-else>
                            <notificationTypeVue
                              :notif="row.item.notification_type"
                            />
                          </div>
                        </div>
                      </template>

                      <template #cell(original_transaction_id)="row">
                        <div style="width: 200px">
                          {{
                            row.item.original_transaction_id
                              ? row.item.original_transaction_id
                              : '-'
                          }}
                        </div>
                      </template>

                      <template #cell(transaction_id)="row">
                        <div style="width: 200px">
                          {{
                            row.item.transaction_id
                              ? row.item.transaction_id
                              : '-'
                          }}
                        </div>
                      </template>

                      <template #cell(purchase_date)="row">
                        <div style="width: 250px">
                          {{
                            row.item.purchase_date
                              ? formatDate(+row.item.purchase_date)
                              : '-'
                          }}
                        </div>
                      </template>

                      <template #cell(revocation_date)="row">
                        <div style="width: 250px">
                          {{
                            row.item.revocation_date &&
                            row.item.revocation_date != 0
                              ? formatDate(+row.item.revocation_date)
                              : '-'
                          }}
                        </div>
                      </template>

                      <template #cell(revocation_reason)="row">
                        <div
                          style="width: 250px"
                          v-if="row.item.revocation_reason == 1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="The App Store refunded the transaction on behalf of the customer due to an actual or perceived issue within your app"
                        >
                          App Issue
                        </div>
                        <div
                          style="width: 250px"
                          v-if="row.item.revocation_reason == 0"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="The App Store refunded the transaction on behalf of the customer for other reasons, for example, an accidental purchase"
                        >
                          Accidental Purchase
                        </div>
                      </template>

                      <template #cell(created)="row">
                        <div style="width: 250px">
                          {{
                            row.item?.created
                              ? formatDate(row.item?.created)
                              : '-'
                          }}
                        </div>
                      </template>

                      <template v-slot:table-busy>
                        <div class="text-center text-dark my-2">
                          <b-spinner
                            class="align-middle"
                            variant="dark"
                            type="grow"
                            label="Loading .."
                          >
                          </b-spinner>
                          Loading ..
                        </div>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
import notificationTypeVue from '../../components/appstore/notificationType.vue';
import paymentview from '../../components/payments/payment.vue';
import Platform from '../../components/platform/platform.vue';
import Itunes from '../../components/orders/detail-itunes.vue';

export default {
  name: 'appstoreNotificationDetail',
  components: {
    notificationTypeVue,
    paymentview,
    Platform,
    Itunes,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'product_id', label: 'Product ID' },
        { key: 'environment', label: 'environment' },
        { key: 'notification_type', label: 'Notification Type' },
        { key: 'original_transaction_id', label: 'Original Transaction' },
        { key: 'transaction_id', label: 'Transaction ID' },
        { key: 'purchase_date', label: 'Purchase Date' },
        { key: 'revocation_date', label: 'Revocation Date' },
        { key: 'revocation_reason', label: 'Revocation Reason' },
        { key: 'created', label: 'Created' },
      ],
      headerOrderline: [
        { key: 'No', label: 'No' },
        { key: 'id', label: 'Orderline ID' },
        { key: 'item_name', label: 'Item Name' },
        { key: 'qty', label: 'Qty' },
        { key: 'price', label: 'Price' },
        { key: 'discount', label: 'Discount' },
        { key: 'final_price', label: 'Final Price' },
      ],
      applePayment: constant.PAYMENT_GATEWAY['Apple iTunes'],
      loadingPayment: true,
    };
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.$store.commit('payments/setLoading', true);
      this.fetchDetailOrder(this.item?.payment?.order_id);
    },
    detailOrder: function() {
      if (!Object.keys(this.detailOrder).length) return;
      this.fetchPayment();
    },
    detailPayment: function() {
      if (!Object.keys(this.detailPayment).length) return;
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
      this.$store.commit('orders/setLoading', false);
      this.$store.commit('payments/setLoading', false);
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Appstore Notification Detail | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.appstore.isLoading,
      isError: (state) => state.appstore.isError,
      errorMessage: (state) => state.appstore.ErrorMessage,
      items: (state) => state.appstore.items,
      item: (state) => state.appstore.item,
      detailOrder: (state) => state.orders.item,
      isLoadingDetailOrder: (state) => state.orders.isLoading,
      detailPayment: (state) => state.payments.item,
      isLoadingDetailPayment: (state) => state.payments.isLoading,
    }),
  },
  created: function() {
    this.$store.commit('orders/setLoading', true);
    this.$store.commit('payments/setLoading', true);
    const transaction_id = this.$route.params.transaction_id;
    let payload = {
      page: this.currentPage,
      limit: this.perPage,
      transaction_id,
    };
    this.fetchByTransactionId(payload);
    this.fetchDetailTransaction({ transaction_id });
  },
  methods: {
    ...mapActions('appstore', [
      'fetchByTransactionId',
      'fetchDetailTransaction',
    ]),
    ...mapActions('discount_code', ['fetchUserByid']),
    ...mapActions('orders', ['fetchOrdersById']),
    ...mapActions('payments', ['getDetailPayment']),

    fetchDetailOrder(orderid) {
      this.fetchOrdersById({ orderid });
    },

    fetchPayment() {
      const payload = {
        orderID: this.detailOrder?.id,
        paymentgateway: this.detailOrder?.paymentgateway_id,
      };
      this.getDetailPayment(payload);
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    routerOrderDetail(order_id) {
      const url = this.$router.resolve('/order/detail?orderid=' + order_id);
      window.open(url.href, '_blank');
    },

    formatPrice(value, currencyCode) {
      if (currencyCode?.toLowerCase() == 'idr') {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },

    getDiscountPrice(totalAmount, finalAmount, currency) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount = parseFloat(totalAmount) - parseFloat(finalAmount);
        return this.formatPrice(parseFloat(discount), currency);
      }
      return '-';
    },

    getDiscountPricePerItem(totalAmount, finalAmount, currency, qty) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount =
          parseFloat(totalAmount) * parseInt(qty) - parseFloat(finalAmount);
        return this.formatPrice(parseFloat(discount), currency);
      }
      return '-';
    },

    clientID(client_id) {
      let clientName = '-';
      for (let [key, value] of Object.entries(constant.CLIENT_ID)) {
        if (parseInt(client_id) == value) {
          clientName = key;
        }
      }
      return clientName.replace('_', ' ');
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
<style>
.loading {
  width: 50px;
  height: 24px;
  background: radial-gradient(circle closest-side, #766df4 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #766df4 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #766df4 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
}
@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
</style>
