var render = function () {
  var _vm$detailOrder2, _vm$detailOrder3, _vm$detailOrder4, _vm$detailOrder5, _vm$detailOrder5$user, _vm$detailOrder6, _vm$detailOrder6$user, _vm$detailOrder7, _vm$detailOrder8, _vm$detailOrder9, _vm$detailOrder10, _vm$detailOrder11, _vm$detailOrder11$cur, _vm$detailOrder12, _vm$detailOrder13, _vm$detailOrder14, _vm$detailOrder14$cur, _vm$detailOrder15, _vm$detailOrder16, _vm$detailOrder17, _vm$detailOrder18, _vm$detailOrder18$cur, _vm$detailOrder19, _vm$detailOrder20, _vm$detailOrder21, _vm$detailOrder21$pay, _vm$detailOrder22, _vm$detailOrder23, _vm$detailOrder24, _vm$detailOrder25, _vm$detailOrder26, _this$detailOrder, _vm$detailPayment, _vm$detailPayment$pay;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Detail Payment Appstore")]), _vm.detailOrder ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card mt-2"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-body"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoadingDetailOrder,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Order ID")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "data-toggle": "tooltip",
      "data-placement": "top",
      "title": "click here to go to detail order page"
    },
    on: {
      "click": function ($event) {
        var _vm$detailOrder;
        return _vm.routerOrderDetail((_vm$detailOrder = _vm.detailOrder) === null || _vm$detailOrder === void 0 ? void 0 : _vm$detailOrder.id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$detailOrder2 = _vm.detailOrder) === null || _vm$detailOrder2 === void 0 ? void 0 : _vm$detailOrder2.id) + " ")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Order Number")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$detailOrder3 = _vm.detailOrder) === null || _vm$detailOrder3 === void 0 ? void 0 : _vm$detailOrder3.order_number) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Email")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$detailOrder4 = _vm.detailOrder) !== null && _vm$detailOrder4 !== void 0 && _vm$detailOrder4.users ? _c('div', [_c('router-link', {
    attrs: {
      "to": {
        path: '/customers/detail/' + ((_vm$detailOrder5 = _vm.detailOrder) === null || _vm$detailOrder5 === void 0 ? void 0 : (_vm$detailOrder5$user = _vm$detailOrder5.users) === null || _vm$detailOrder5$user === void 0 ? void 0 : _vm$detailOrder5$user.email)
      },
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s((_vm$detailOrder6 = _vm.detailOrder) === null || _vm$detailOrder6 === void 0 ? void 0 : (_vm$detailOrder6$user = _vm$detailOrder6.users) === null || _vm$detailOrder6$user === void 0 ? void 0 : _vm$detailOrder6$user.email) + " ")])], 1) : _c('div', [_vm._v("-")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Transaction Time")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$detailOrder7 = _vm.detailOrder) === null || _vm$detailOrder7 === void 0 ? void 0 : _vm$detailOrder7.created)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Modified")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$detailOrder8 = _vm.detailOrder) === null || _vm$detailOrder8 === void 0 ? void 0 : _vm$detailOrder8.modified)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Status Order")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('div', {
    staticClass: "float-left",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('paymentview', {
    attrs: {
      "payment": +((_vm$detailOrder9 = _vm.detailOrder) === null || _vm$detailOrder9 === void 0 ? void 0 : _vm$detailOrder9.order_status)
    }
  })], 1)])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Renewal Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$detailOrder10 = _vm.detailOrder) !== null && _vm$detailOrder10 !== void 0 && _vm$detailOrder10.is_renewal ? _c('span', {
    staticClass: "badge badge-pill badge-primary"
  }, [_vm._v(" Renewal ")]) : _c('span', {
    staticClass: "badge badge-pill badge-danger"
  }, [_vm._v(" Non-Renewal ")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Total Amount")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$detailOrder11 = _vm.detailOrder) === null || _vm$detailOrder11 === void 0 ? void 0 : (_vm$detailOrder11$cur = _vm$detailOrder11.currency_code) === null || _vm$detailOrder11$cur === void 0 ? void 0 : _vm$detailOrder11$cur.toUpperCase()) + " " + _vm._s(_vm.formatPrice((_vm$detailOrder12 = _vm.detailOrder) === null || _vm$detailOrder12 === void 0 ? void 0 : _vm$detailOrder12.total_amount, (_vm$detailOrder13 = _vm.detailOrder) === null || _vm$detailOrder13 === void 0 ? void 0 : _vm$detailOrder13.currency_code)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$detailOrder14 = _vm.detailOrder) === null || _vm$detailOrder14 === void 0 ? void 0 : (_vm$detailOrder14$cur = _vm$detailOrder14.currency_code) === null || _vm$detailOrder14$cur === void 0 ? void 0 : _vm$detailOrder14$cur.toUpperCase()) + " " + _vm._s(_vm.getDiscountPrice((_vm$detailOrder15 = _vm.detailOrder) === null || _vm$detailOrder15 === void 0 ? void 0 : _vm$detailOrder15.total_amount, (_vm$detailOrder16 = _vm.detailOrder) === null || _vm$detailOrder16 === void 0 ? void 0 : _vm$detailOrder16.final_amount, (_vm$detailOrder17 = _vm.detailOrder) === null || _vm$detailOrder17 === void 0 ? void 0 : _vm$detailOrder17.currency_code)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Final Amount")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$detailOrder18 = _vm.detailOrder) === null || _vm$detailOrder18 === void 0 ? void 0 : (_vm$detailOrder18$cur = _vm$detailOrder18.currency_code) === null || _vm$detailOrder18$cur === void 0 ? void 0 : _vm$detailOrder18$cur.toUpperCase()) + " " + _vm._s(_vm.formatPrice((_vm$detailOrder19 = _vm.detailOrder) === null || _vm$detailOrder19 === void 0 ? void 0 : _vm$detailOrder19.final_amount, (_vm$detailOrder20 = _vm.detailOrder) === null || _vm$detailOrder20 === void 0 ? void 0 : _vm$detailOrder20.currency_code)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Payment Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$detailOrder21 = _vm.detailOrder) === null || _vm$detailOrder21 === void 0 ? void 0 : (_vm$detailOrder21$pay = _vm$detailOrder21.payment) === null || _vm$detailOrder21$pay === void 0 ? void 0 : _vm$detailOrder21$pay.name) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Platform Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('div', {
    staticClass: "float-left"
  }, [_c('Platform', {
    attrs: {
      "platform": +((_vm$detailOrder22 = _vm.detailOrder) === null || _vm$detailOrder22 === void 0 ? void 0 : _vm$detailOrder22.platform_id)
    }
  })], 1)])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Client ID")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.clientID((_vm$detailOrder23 = _vm.detailOrder) === null || _vm$detailOrder23 === void 0 ? void 0 : _vm$detailOrder23.client_id)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Tier Code")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$detailOrder24 = _vm.detailOrder) !== null && _vm$detailOrder24 !== void 0 && _vm$detailOrder24.tier_code ? (_vm$detailOrder25 = _vm.detailOrder) === null || _vm$detailOrder25 === void 0 ? void 0 : _vm$detailOrder25.tier_code : '-') + " ")])])])], 1)])])]) : _vm._e(), _vm.detailOrder ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(1), _c('div', {
    staticClass: "card-body"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoadingDetailOrder,
      "rounded": "sm"
    }
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.headerOrderline,
      "hover": "",
      "responsive": "xl",
      "items": (_vm$detailOrder26 = _vm.detailOrder) === null || _vm$detailOrder26 === void 0 ? void 0 : _vm$detailOrder26.orderlines,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(id)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.id) + " ")];
      }
    }, {
      key: "cell(item_name)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.name) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.quantity) + " ")];
      }
    }, {
      key: "cell(price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.currency_code) + " " + _vm._s(_vm.formatPrice(row.item.price, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(discount)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.currency_code) + " " + _vm._s(_vm.getDiscountPricePerItem(row.item.price, row.item.final_price, row.item.currency_code, row.item.quantity)) + " ")];
      }
    }, {
      key: "cell(final_price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.currency_code) + " " + _vm._s(_vm.formatPrice(row.item.final_price, row.item.currency_code)) + " ")];
      }
    }], null, false, 2705059666)
  })], 1)], 1)])])]) : _vm._e(), _vm.detailOrder ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(2), _c('div', {
    staticClass: "card-body"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoadingDetailPayment,
      "rounded": "sm"
    }
  }, [((_this$detailOrder = this.detailOrder) === null || _this$detailOrder === void 0 ? void 0 : _this$detailOrder.paymentgateway_id) == _vm.applePayment && (_vm$detailPayment = _vm.detailPayment) !== null && _vm$detailPayment !== void 0 && (_vm$detailPayment$pay = _vm$detailPayment.paymentappleiaps) !== null && _vm$detailPayment$pay !== void 0 && _vm$detailPayment$pay.length ? _c('div', [_c('Itunes', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e()])], 1)])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card mt-2"
  }, [_vm._m(3), _c('div', {
    staticClass: "card-body"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "350px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "show-empty": "",
      "responsive": "xl",
      "hover": "",
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(product_id)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "350px"
          }
        }, [_vm._v(" " + _vm._s(row.item.product_id ? row.item.product_id : '-') + " ")])];
      }
    }, {
      key: "cell(environment)",
      fn: function (row) {
        return [row.item.environment == 'Production' ? _c('div', {
          staticStyle: {
            "width": "150px"
          }
        }, [_c('span', {
          staticClass: "badge badge-pill bg-primary"
        }, [_vm._v(_vm._s(row.item.environment ? row.item.environment : '-'))])]) : row.item.environment == 'Sandbox' ? _c('div', {
          staticStyle: {
            "width": "150px"
          }
        }, [_c('span', {
          staticClass: "badge badge-pill bg-warning"
        }, [_vm._v(_vm._s(row.item.environment ? row.item.environment : '-'))])]) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "cell(notification_type)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "180px"
          }
        }, [!row.item.notification_type ? _c('div', [_vm._v("-")]) : _c('div', [_c('notificationTypeVue', {
          attrs: {
            "notif": row.item.notification_type
          }
        })], 1)])];
      }
    }, {
      key: "cell(original_transaction_id)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s(row.item.original_transaction_id ? row.item.original_transaction_id : '-') + " ")])];
      }
    }, {
      key: "cell(transaction_id)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s(row.item.transaction_id ? row.item.transaction_id : '-') + " ")])];
      }
    }, {
      key: "cell(purchase_date)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(row.item.purchase_date ? _vm.formatDate(+row.item.purchase_date) : '-') + " ")])];
      }
    }, {
      key: "cell(revocation_date)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(row.item.revocation_date && row.item.revocation_date != 0 ? _vm.formatDate(+row.item.revocation_date) : '-') + " ")])];
      }
    }, {
      key: "cell(revocation_reason)",
      fn: function (row) {
        return [row.item.revocation_reason == 1 ? _c('div', {
          staticStyle: {
            "width": "250px"
          },
          attrs: {
            "data-toggle": "tooltip",
            "data-placement": "top",
            "title": "The App Store refunded the transaction on behalf of the customer due to an actual or perceived issue within your app"
          }
        }, [_vm._v(" App Issue ")]) : _vm._e(), row.item.revocation_reason == 0 ? _c('div', {
          staticStyle: {
            "width": "250px"
          },
          attrs: {
            "data-toggle": "tooltip",
            "data-placement": "top",
            "title": "The App Store refunded the transaction on behalf of the customer for other reasons, for example, an accidental purchase"
          }
        }, [_vm._v(" Accidental Purchase ")]) : _vm._e()];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        var _row$item, _row$item2;
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s((_row$item = row.item) !== null && _row$item !== void 0 && _row$item.created ? _vm.formatDate((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : _row$item2.created) : '-') + " ")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v("Order Transaction Detail")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v("Orderlines Detail")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v("Detail Payment")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v("Appstore Transactions")])]);
}]

export { render, staticRenderFns }